import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const About: React.FC = () => {
    return (
        <div style={{ 
            backgroundImage: 'url(https://i.postimg.cc/ZYw3KD16/imgonline.jpg)', 
            backdropFilter: 'blur(8px)' 
        }}>
            <Container maxWidth="md" className='mt-0 mb-0 p-4 '>
                <Paper sx={{ p: 5, border: "none", backgroundColor: "#fffff", boxShadow: "none", color:"black",  }}>
                    <Typography variant="h3" gutterBottom component="h1" color="primary">
                        About Us
                    </Typography>
                    <Typography variant="h5" paragraph>
                        Welcome to Ming Chinese!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Step into the vibrant world of Ming Chinese, a culinary haven nestled in the bustling 
                        streets of Mahim, Mumbai. Since our inception, we've been on a relentless quest to redefine 
                        the dining experience, fusing the rich tapestry of Indian spices with the time-honored traditions 
                        of Indo-Chinese cuisine. What sets Ming Chinese apart is our unwavering commitment to freshness and 
                        authenticity. From the moment you step through our doors, you'll be greeted by the enticing aroma of 
                        freshly prepared ingredients. We take pride in sourcing the finest produce, locally ensuring 
                        that each dish is a celebration of flavor and quality. Our talented team of chefs, steeped in the traditions 
                        of Indo-Chinese cuisine, work tirelessly to bring you an unparalleled dining experience.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        But Ming Chinese is more than just a restaurant; it's a culinary 
                        journey through time and taste. Our menu reflects the diverse tapestry 
                        of flavors that define Indo-Chinese cuisine, from fiery Szechuan dishes 
                        to comforting chow-meins infused with aromatic spices. Each dish tells a story, 
                        a symphony of flavors that pay homage to centuries-old culinary traditions while 
                        embracing the vibrant spirit of Mumbai's culinary landscape.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        At Ming Chinese, hospitality is at the heart of everything we do. 
                        From the warm smiles that greet you at the door to the impeccable service 
                        that accompanies every meal, we strive to make every visit memorable. Whether 
                        you're celebrating a special occasion with loved ones or simply craving a delicious 
                        meal, Ming Chinese is the perfect destination.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Step into Ming Chinese and embark on a culinary adventure unlike 
                        any other. Experience the harmony of flavors, the warmth of hospitality, 
                        and the magic of Ming. Welcome to a world where every meal is a celebration, 
                        and every bite is a journey. Welcome to Ming Chinese, where passion meets perfection, 
                        and every dish tells a story.
                    </Typography>
                </Paper>
            </Container>
        </div>
    );
};

export default About;
