import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginDetails, OrderingWrapper, Wrapper } from "./components";
import {
  Index,
  NotFound,
  Order,
  Orders,
  Account,
  Addresses,
  Checkout,
  About,
  Locate,
  Menu
} from "./pages";
import { useUserInfoStore } from "./lib/state";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./config/firebase";
import { doc, getDoc } from "firebase/firestore";
import Profile from "./pages/account/Profile";
import Favorties from "./pages/account/Favorites";
import { User } from "./lib/interfaces";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Wrapper />,
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "menu",
        element: <Menu />,
      },
      {
        path: "locate-us",
        element: <Locate />,
      },
    ],
    errorElement: <NotFound />,
  },
  {
    path: "/order",
    element: <OrderingWrapper />,
    children: [
      {
        index: true,
        element: <Order />,
      },
      {
        path: "logindetails",
        element: <LoginDetails />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "account",
        element: <Account />,
        children: [
          {
            index: true,
            element: <Profile />,
          },
          {
            path: "orders",
            element: <Orders />,
          },
          {
            path: "favorites",
            element: <Favorties />,
          },
          {
            path: "addresses",
            element: <Addresses />,
          },
        ],
      },
    ],
    errorElement: <NotFound />,
  },
]);

const App = () => {
  const [user, setUser, setLoading] = useUserInfoStore((state) => [
    state.user,
    state.setUser,
    state.setLoading,
  ]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userCred) => {
      if (userCred) {
        if (user && user.id === userCred.uid) {
          setLoading(false);
          return;
        }

        const getUserData = async () => {
          const user = await getDoc(doc(db, "users", userCred.uid));
          const data = user.data();
          if (!data) return;

          setUser(data as User);
          setLoading(false);
        };
        getUserData();
      } else {
        setUser(undefined);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
