import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { auth } from "./firebase";

const axiosInstance = axios.create({
  baseURL: "http://localhost:8000/api/v1",
});

const onRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  // wait for the current user to be available

  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    config.headers.Authorization = token;
  }

  return config;
};

const onRequestError = (error: AxiosError) => Promise.reject(error);

axiosInstance.interceptors.request.use(onRequest, onRequestError);

export default axiosInstance;
