import { Paper, Container } from '@mui/material';

function Menu() {
    return (
        <div style={{
            backgroundImage: 'url(https://i.postimg.cc/ZYw3KD16/imgonline.jpg)',
            backdropFilter: 'blur(8px)',
            padding: '1rem',
            margin: '0',
        }}>
            <Container className="flex flex-col items-center justify-center w-full bg-white p-2 rounded-3xl">
                <Paper className="bg-white rounded-lg w-full max-w-4xl">
                    <h1 className='text-center p-0 m-0'>Menu</h1>
                    
                    {/* PDF Viewer for larger screens using <object> */}
                    <div className="hidden lg:block relative w-full h-[80vh] bg-[#EA0008]">
                        <object
                            id="pdf-object"
                            data="/assets/Menu/MingMenu.pdf"
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                            <p>PDF cannot be displayed. <a href="/assets/Menu/MingMenu.pdf" download>Download PDF</a></p>
                        </object>
                    </div>

                    <div className="lg:hidden flex flex-col items-center gap-4">
                        <img src="/assets/Menu/MingMenu-1.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-2.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-3.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-4.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-5.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-6.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-7.webp" alt="Menu" className="w-full h-auto" />
                        <img src="/assets/Menu/MingMenu-8.webp" alt="Menu" className="w-full h-auto" />
                    </div>

                    {/* Download button for mobile */}
                    {/* <div className="lg:hidden flex flex-col items-center">
                        <a
                            href="/assets/Menu/MingMenu.pdf"
                            download
                            className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-300"
                        >
                            Download PDF
                        </a>
                    </div> */}
                </Paper>
            </Container>
        </div>
    );
}

export default Menu;
