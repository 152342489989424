import React, { useEffect, useState } from "react";
import {
  ImageList,
  ImageListItem,
  Box,
  Grid,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const overlayStyles =
  "relative w-full h-[90vh] flex justify-center items-center bg-black bg-opacity-50 ";

const contentStyles = "text-center text-black ";

type ItemData = {
  img: string;
  title: string;
  author: string;
  rows: number;
  cols: number;
  featured: boolean;
  progress: number; // Add this line
};

const itemData: ItemData[] = [
  {
    img: "/assets/menu/Chicken lollipop.webp",
    title: "Chicken Lollipop",
    author: "Lorem ipsum dolor sit amet",
    rows: 3,
    cols: 3,
    featured: true,
    progress: 40,
  },
  {
    img: "/assets/menu/Chicken noodle.webp",
    title: "Hakka Noodles",
    author: "Lorem ipsum dolor sit amet",
    rows: 3,
    cols: 3,
    featured: true,
    progress: 40,
  },
  {
    img: "/assets/menu/Chicken crispy.webp",
    title: "Crispy Chicken",
    author: "Lorem ipsum dolor sit amet",
    rows: 3,
    cols: 3,
    featured: true,
    progress: 35,
  },
  {
    img: "/assets/menu/Schezwan rice.webp",
    title: "Chicken Schezwan Rice",
    author: "Lorem ipsum dolor sit amet",
    rows: 3,
    cols: 3,
    featured: true,
    progress: 45,
  },
];

const PhotoSlideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const images = [
    "/assets/menu/Chicken crispy.webp",
    "/assets/menu/Finger chicken.webp",
    "/assets/menu/Chicken lollipop.webp",
    "/assets/menu/Chicken noodle.webp",
    "/assets/menu/Honey chilly chicken.webp",
    "/assets/menu/Black bean chilly chicken.webp",
    "/assets/menu/Chicken chilly dry.webp",
    "/assets/menu/Kung pao chicken.webp",
    "/assets/menu/Oyster chilly chicken.webp",
    "/assets/menu/Chicken Pot Rice.webp",
    "/assets/menu/Singapore fried rice.webp",
    "/assets/menu/Singapore noodle.webp",
    "/assets/menu/Triple schezwan rice.webp",
    "/assets/menu/Schezwan rice.webp",
  ];

  const titles = [
    "Crispy Chicken",
    "Finger Chicken",
    "Chicken Lollipop",
    "Hakka Noodles",
    "Honey Chilly Chicken",
    "Black Bean Chilly Chicken",
    "Chicken Chilly Dry",
    "Kung Pao Chicken",
    "Oyster Chilly Chicken",
    "Chicken Pot Rice",
    "Singapore Fried Rice",
    "Singapore Noodle",
    "Chicken Triple Schezwan Rice",
    "Chicken Schezwan Rice",
  ];

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isPaused, images.length]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div
      className="flex-1 relative group"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <img
        src={images[currentIndex]}
        className="w-full md:mt-0 md:mb-4 md:ml-4 md:mr-4 px-4 rounded-[10%] transition-opacity duration-1000 ease-in-out"
        alt={titles[currentIndex]}
      />
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white text-2xl font-bold drop-shadow-lg">
        {titles[currentIndex]}
      </div>
      <IconButton
        onClick={prevImage}
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white hover:bg-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        aria-label="Previous Image"
        size="large"
      >
        <ArrowBack />
      </IconButton>
      <IconButton
        onClick={nextImage}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white hover:bg-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        aria-label="Next Image"
        size="large"
      >
        <ArrowForward />
      </IconButton>
    </div>
  );
};

const Index = () => {
  return (
    <div>
      {/* First background image */}
      <div className={overlayStyles}>
        <div className="relative w-full h-full">
          <div className="absolute inset-0 w-full min-h-5 h-full bg-no-repeat bg-cover bg-center bg-[#EA0008] bg-[url(https://i.postimg.cc/ZYw3KD16/imgonline.jpg)]"></div>
          <div
            className={
              contentStyles +
              "flex flex-col justify-center items-center text-center h-full"
            }
          >
            <img
              src="/assets/imgs/Asset 1.webp"
              alt="LOGO"
              className="z-40 h-[8rem] sm:h-[10rem] md:h-[12rem] lg:h-[15rem] xl:h-[18rem]"
            />
            <h1 className="z-30 text-white mb-0">
              Chinese food at its best only at Ming Chinese
            </h1>
            <p className="z-30 text-white mt-0">
              Come and take a dive into the journey of mouthwatering food.
            </p>
            {/* <Link to="/order">
              <Button className="z-30 text-2xl hover-none">Order Online</Button>
            </Link> */}
          </div>
        </div>
      </div>

      {/* Second background image */}
      <div className={overlayStyles}>
        <div className=" h-full bg-cover bg-no-repeat bg-[url('https://images.unsplash.com/photo-1495195129352-aeb325a55b65?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]">
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 md:mt-[10%] md:ml-[5%]">
              <PhotoSlideshow />
            </div>
            <div className="flex-1 md:mt-60 md:ml-12 md:mr-12 text-center">
              <h1>A Little About Us</h1>
              <p>
                <b>
                  "Welcome to Ming Chinese, where flavors intertwine to create
                  an unforgettable dining experience! Nested in the heart of
                  Mahim, Mumbai, Ming Chinese proudly blends the aromatic spices
                  of Indian cuisine with the traditional techniques of Chinese
                  cooking. Our restaurant is a vibrant celebration of
                  Chinese fusion, where every dish tells a story of
                  culinary craftsmanship and cultural harmony."
                </b>
              </p>
              {/* <Link to="/order">
                <Button className="focus:outline-dashed rounded-lg text-xl">
                  Order Online
                </Button>
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      {/* Third background image */}
      <Box className="relative w-full mb-0 min-h-[90vh] bg-cover bg-no-repeat bg-[url('../public/assets/imgs/bg.jpg')]">
        <Grid container spacing={2}>
          {/* Images Grid */}
          <Grid item xs={12} lg={6} className="flex justify-center">
            <ImageList
              className="max-w-[80%] lg:max-w-full px-4 h-auto"
              cols={2}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.img} sx={{ position: "relative" }}>
                  <img src={item.img} alt={item.title} loading="lazy" />
                  <div className="absolute bottom-0 left-0 right-0 p-2 ">
                    <div className="text-white font-bold">{item.title}</div>
                    <LinearProgress
                      variant="determinate"
                      value={item.progress}
                      sx={{
                        width: "80%",
                        mt: 1,
                        backgroundColor: "transparent",
                        color: "primary",
                        marginTop: "none",
                      }}
                    />
                  </div>
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>

          {/* Text Grid */}
          <Grid
            item
            xs={12}
            lg={6}
            className="flex flex-col justify-center items-center p-4"
          >
            <div className="px-4 h-auto">
              <h2 className="mb-0 text-center">Food Always Hot & Fresh</h2>
              <p className="mt-0 text-center">
                {" "}
                At Ming Chinese, freshness is our promise and passion. We
                believe that great food starts with the finest ingredients,
                which is why we source only the freshest produce and spices from
                trusted suppliers. From the crisp vegetables to every component
                of our dishes is carefully selected to ensure premium quality
                and exceptional taste. What truly sets us apart is our
                commitment to crafting everything in-house daily. Our skilled
                chefs pour their expertise and dedication into each dish,
                preparing everything from scratch to guarantee unparalleled
                freshness and flavor. With every bite, you can taste the
                difference that our dedication to quality makes.
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Index;
